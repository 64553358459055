import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '../Form';
import FormikTextInput from './FormikTextInput';


const FormikViewingKeynestInputs = ({
    inputIdPrefix,
    inputNamesMap
}) => {
    const makeInputId = inputId => {
        if (inputIdPrefix) {
            return `${inputIdPrefix}-${inputId}`;
        }
        return inputId;
    }

    const makeInputName = inputName => {
        if (inputNamesMap && inputNamesMap[inputName]) {
            return inputNamesMap[inputName];
        }
        return inputName;
    }

    return (
        <React.Fragment>
            <FormGroup>
                <FormikTextInput
                    id={makeInputId('keynest-store-location-input')}
                    name={makeInputName('keynest_store_location')}
                    label='KeyNest Address'
                    placeholder='Enter The KeyNest Address'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('keynest-collection-code-input')}
                    name={makeInputName('keynest_collection_code')}
                    label='KeyNest Collection Code'
                    placeholder='Enter The Collection Code'
                />
            </FormGroup>

            <FormGroup>
             <FormikTextInput
                    id={makeInputId('keynest-dropoff-code-input')}
                    name={makeInputName('keynest_dropoff_code')}
                    label='KeyNest Drop-off Code'
                    placeholder='Enter The Drop-off Code'
                />
            </FormGroup>
        </React.Fragment>
    )
}

FormikViewingKeynestInputs.propTypes = {
    /**
     * A prefix for the input ids.
     */
    inputIdPrefix: PropTypes.string,

    /**
     * A map of names for the input to use
     * instead of the default name.
     * E.g.
     * {
     *    'collection_name': 'new_collection_name,
     *    'collection_phone': 'new_collection_phone,
     * }
     */
    inputNamesMap: PropTypes.object
}

export default FormikViewingKeynestInputs;