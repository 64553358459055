import React from 'react';
import { Alert } from 'reactstrap';
// import * as yup from 'yup';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import moment from 'moment';
import {
    getFullDateText,
    getTimeText,
    momentDateFormat,
    momentTimeFormat,
} from '../../helpers/dates';
import { getSelectOptions } from '../../helpers/formResources';
import { getAllResources as getAllAccessArrangements } from '../accessArrangements/actions';
import {
    reducerName as accessArrangementsReducerName,
    selectOptionValue as accessArrangementSelectOptionValue,
    selectOptionText as accessArrangementSelectOptionText,
} from '../accessArrangements/schema';
import { getAllResources as getAllReturnArrangements } from '../returnArrangements/actions';
import {
    reducerName as returnArrangementsReducerName,
    selectOptionValue as returnArrangementSelectOptionValue,
    selectOptionText as returnArrangementSelectOptionText,
} from '../returnArrangements/schema';
import { getAllResources as getAllViewingDateTypes } from '../viewingDateTypes/actions';
import {
    reducerName as viewingDateTypesReducerName,
    selectOptionValue as viewingDateTypeSelectOptionValue,
    selectOptionText as viewingDateTypeSelectOptionText,
} from '../viewingDateTypes/schema';
import { getAllResources as getAllViewingLengths } from '../viewingLengths/actions';
import {
    reducerName as viewingLengthsReducerName,
    selectOptionValue as viewingLengthSelectOptionValue,
    selectOptionText as viewingLengthSelectOptionText,
} from '../viewingLengths/schema';
import {
    selectOptionValue as viewingTypeSelectOptionValue,
    selectOptionText as viewingTypeSelectOptionText,
} from '../viewingTypes/schema';
// Having reducerName in a different files allow the whole schema not to be compiled,
// before redux initializes, causing valuesFetcher to have an undefined fetcher callback.
// Please do not move
import { reducerName } from './variables';
import {Link} from "react-router-dom";
export { reducerName };

const {
    REACT_APP_SPECIFIC_DATE_TYPE_ID,
    REACT_APP_FILE_UPLOAD_BASE_URL
} = process.env;

export const resourceDisplayName = 'Appointment';

export const resourcesDisplayName = 'Appointments';

export const resourceBaseRoute = 'appointments';

export const keyField = 'id';

export const nameField = 'id';

export const columns = [
    {
        dataField: 'id',
        text: 'Appointment ID',
        className: 'col-md-12',
    },
    {
        dataField: 'main_viewing_length',
        text: 'Type',
        className: 'col-md-12',
        formatter: (main_viewing_length) => {
            if (!isNil(main_viewing_length) && !isNil(main_viewing_length.viewing_type)) {
                return `${main_viewing_length.viewing_type.name} - ${main_viewing_length.description}`;
            } else if (!isNil(main_viewing_length) && isNil(main_viewing_length.viewing_type)) {
                return `${main_viewing_length.description}`;
            }
            return '';
        }
    },
    {
        dataField: 'resource',
        text: 'Recurring Appointment',
        className: 'col-md-12',
        formatter: resource => (
            (!isNil(resource.viewing_recurring) || !isNil(resource.parent_viewing_recurring))
                ? "Yes" : "No"
        )
    },
    {
        text: 'Your Reference',
        dataField: 'reference',
        className: 'col-md-12 col-12',
    },
    {
        dataField: 'property.postcode',
        text: 'Postcode',
        className: 'col-md-4 col-12',
        formatter: (value) => value ? value.toUpperCase() : 'N/A',
    },
    {
        dataField: 'property.full_address',
        text: 'Address',
        className: 'col-md-12 col-12',
    },
    {
        dataField: 'preferred_date_1',
        text: 'Date/Time',
        className: 'col-md-12 col-12',
        formatter: (preferred_date_1) => preferred_date_1 ? `${getFullDateText(preferred_date_1)} ${getTimeText(preferred_date_1)}` : 'N/A',
    },
    {
        dataField: 'created',
        text: 'Submitted at',
        className: 'col-md-12 col-12',
        formatter: (created) => created ? `${getFullDateText(created)} ${getTimeText(created)}` : 'N/A',
    },
    {
        dataField: 'resource',
        text: 'Status',
        className: 'col-md-12 col-12',
        formatter: resource => (
            resource.status === 1 && !isNil(resource.confirmed_appointment)
                ? `${resource.status_text} - Viewber Confirmed`
                : resource.status_text
        ),
    },
    {
        dataField: 'user.full_name',
        text: 'Booked By',
        className: 'col-md-12 col-12',
    },
    {
        dataField: 'resource',
        textFormatter: (resource) =>  (
            resource.status === 1
                && resource.confirmed_appointment
                && resource.confirmed_appointment.viewber
        ) ?  'Viewber Info' : '',
        className: 'col-md-12 col-12',
        formatter: (resource) => {
            if (!resource.confirmed_appointment || !resource.confirmed_appointment.viewber) {
                return null;
            }

            const viewing = resource;
            const viewber = resource.confirmed_appointment.viewber;
            let viewberFullNameAndMaybePhone = 'TBD';
            let viewberMaskedPhoneInformationBody = null;

            // Set the viewber name.
            viewberFullNameAndMaybePhone = viewing.requires_key_delivery_by_post || viewing.user.viewber_full_name_enabled
                ? viewber.full_name
                : viewber.partial_name;

            // If the viewber phone_type is default then append the phone to the
            // viewber name.
            if (viewber.phone_type === 'default') {
                viewberFullNameAndMaybePhone += ' ' + viewber.phone;
            }

            // If the viewber phone_type is masked then build the masked
            // phone information.
            if (viewing.confirmed_appointment.viewber.phone_type === 'masked') {
                
                let hoursSinceFeedback = 0;
                if (viewing.confirmed_appointment.feedback_submitted_at !== null) {
                    const feedbackSubmittedAt = moment(viewing.confirmed_appointment.feedback_submitted_at);
                    hoursSinceFeedback = moment().diff(feedbackSubmittedAt, 'hours');
                }

                // If the viewing has status 1 then build the viewber
                // masked phone information body.
                if (viewing.status === 1
                    || (
                        viewing.status === 3
                        && viewing.confirmed_appointment.feedback_sent === 1
                        && hoursSinceFeedback <= 24
                    )
                ) {
                    viewberMaskedPhoneInformationBody = (
                        <p className='m-0 font-weight-semibold'>
                            {viewing.user.client?.twilio_pin
                                ? (
                                    <span>
                                        Please call <strong>{viewber.phone} </strong>
                                        and enter the Pin number <strong> {viewing.user.client?.twilio_pin?.pin || ''} </strong>
                                        and the appointment id <strong>{viewing.id}</strong> to contact the viewber.
                                    </span>
                                )                                
                                : (
                                    <Link to="/me/phone-pin">
                                        Please generate a phone pin to be able to call the Viewber
                                    </Link>
                                )
                            }
                        </p>
                    )
                }
            }

            return (
                <React.Fragment>
                    <span>{viewberFullNameAndMaybePhone}</span>
                    {viewberMaskedPhoneInformationBody}
                </React.Fragment>
            )
        },
    },
    {
        dataField: 'cancelled',
        className: 'col-12',
        formatter: cancelled => {
            if (!cancelled) {
                return null;
            }
            return <span className='badge badge-danger'>Cancelled</span>
        },
    },
];

export const sortingOptions = [
    {
        display_name: 'Appointment ID',
        name: 'id',
        direction: 'desc',
    },
    {
        display_name: 'Appointment ID',
        name: 'id',
        direction: 'asc',
    },
    {
        display_name: 'Date',
        name: 'preferred_date_1',
        direction: 'desc',
    },
    {
        display_name: 'Date',
        name: 'preferred_date_1',
        direction: 'asc',
    },
    {
        display_name: 'Your Reference',
        name: 'reference',
        direction: 'asc',
    },
    {
        display_name: 'Your Reference',
        name: 'reference',
        direction: 'desc',
    },
];

export const filters = [
    'status'
]

export const defaultSortingOption = { ...sortingOptions[0] };

const searchColumns = [
    'Postcode',
    'Appointment ID',
    'Reference'
];

export const searchTextInputPlaceholder = `Search by ${searchColumns.join(', or ')}`;

export const pageSize = 10;

const viewingDateTypeValuesFetcher = {
    reducerName: viewingDateTypesReducerName,
    fetcher: getAllViewingDateTypes,
    fetcherName: 'getAllViewingDateTypes',
};

const accessArrangementValuesFetcher = {
    reducerName: accessArrangementsReducerName,
    fetcher: getAllAccessArrangements,
    fetcherName: 'getAllAccessArrangements',
};

const returnArrangementValuesFetcher = {
    reducerName: returnArrangementsReducerName,
    fetcher: getAllReturnArrangements,
    fetcherName: 'getAllReturnArrangements',
};

const viewingLengthValuesFetcher = {
    reducerName: viewingLengthsReducerName,
    fetcher: getAllViewingLengths,
    fetcherName: 'getAllViewingLengths',
    onFetchSuccessCallback: (resources, schema) => {
        const viewingTypes = sortBy(
            uniqWith(
                map(resources, 'viewing_type'),
                isEqual
            ),
            ['position']
        ).filter(
            viewing_type => viewing_type && viewing_type.id !== 11
        );

        const viewingTypevalues = getSelectOptions(
            viewingTypes,
            schema.viewing_type_id.selectOptionText,
            schema.viewing_type_id.selectOptionValue
        );

        schema.viewing_type_id.values = viewingTypevalues;
        schema.viewing_length_id.values = [];

        return { ...schema };
    },
};

export const schema = {
    access_arrangement_divider: {
        type: 'divider',
        label: 'Access Arrangements',
        content: (
            <>
                <Alert color="danger" key={`accessArrangementsContentAlert`}>
                    Please note that we do not arrange appointments directly with vendors, tenants or any other third party,
                    {' '}
                    so please ensure that all arrangements have been agreed with any third party prior to placing a booking.
                </Alert>
                <p key={`accessArrangementsContentParagraph`}>
                    If the date of the key collection is not the same as the date of the appointment,
                    {' '}
                    please detail in the Notes for Viewber section.
                </p>
            </>
        ),
    },
    add_ons_divider: {
        type: 'divider',
        label: 'Additional Services',
        content: '',
    },
    id: {
        type: 'hidden',
        rules: undefined,
        value: '',
        errors: [],
        prepend: 'VV'
    },
    access_arrangement_id: {
        type: 'select',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Please select - How should the property access be arranged?',
        emptyOption: 'Please select - How should the property access be arranged?',
        values: [],
        selectOptionValue: accessArrangementSelectOptionValue,
        selectOptionText: accessArrangementSelectOptionText,
        valuesFetcher: accessArrangementValuesFetcher,
        fetchValuesAsyncState: {
            loading: false,
            error: false,
            errorContent: null
        }
    },
    brochure: {
        type: 'url',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Property Brochure URL / Property Portal URL',
        placeholder: 'Property Brochure URL / Property Portal URL',
    },
    brochure_not_applicable: {
        type: 'checkbox',
        rules: undefined,
        value: 0,
        errors: [],
        label: 'I do not have a property brochure URL / property portal URL. Please tick if so.',
    },
    category: {
        type: 'select',
        value: '',
        rules: undefined,
        errors: [],
        label: 'Property Category *',
        emptyOption: 'Please select the property category',
        values: [
            {
                text: 'Sale',
                value: 'sale',
            },
            {
                text: 'Rent',
                value: 'rent',
            },
            {
                text: 'Auction',
                value: 'auction',
            },
        ],
    },
    client_details_divider: {
        type: 'divider',
        label: 'Your Details',
    },
    client_notes: {
        type: 'textarea',
        label: 'Property Notes (will carry over to all bookings made on this property)',
        rules: undefined,
        value: '',
        errors: [],
        rows: 8,
    },
    collection_city: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
    },
    collection_line_1: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Address Line 1',
    },
    collection_line_2: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Address Line 2',
    },
    collection_name: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Contact Name',
    },
    collection_phone: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Phone',
    },
    collection_postcode: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        lable: 'Postcode',
    },
    collection_travel_time: {
        type: 'hidden',
        rules: undefined,
        formText: 'Please calculate the driving travel time, in minutes, between the property and the collection address using Google Maps.',
        value: '',
        errors: [],
        label: 'Travel Time (mins)',
    },
    concierge_location: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
    },
    date_divider: {
        type: 'divider',
        label: 'Appointment Details',

    },
    date_time_notice_alert: {
        type: 'divider',
        label: 'Important notes',
        content: (
            <Alert color="danger" key={`BookingDateChargeAlert`}>
                For appointments less than 4 business hours away from the time of booking, an extra £2 charge will be added on the final invoice. For the avoidance of doubt, business hours include Monday to Sunday 09:00 - 18:00.
            </Alert>
        ),
    },
    date_type: {
        type: 'select',
        rules: undefined,
        value: REACT_APP_SPECIFIC_DATE_TYPE_ID,
        errors: [],
        emptyOption: 'Select a date option...',
        values: [],
        label: 'Select a time/date for the viewing or choose a deadline time/date before which other tasks should be completed.',
        selectOptionValue: viewingDateTypeSelectOptionValue,
        selectOptionText: viewingDateTypeSelectOptionText,
        valuesFetcher: viewingDateTypeValuesFetcher,
    },
    date_type_deadline_alert: {
        type: 'divider',
        label: 'Important notes',
        content: (
            <Alert color="warning">
                Deadline Appointments can be only cancelled or amended within one hour of creation.
            </Alert>
        ),
    },
    discount_code: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        style: { display: 'none' },
    },
    discount_divider: {
        type: 'divider',
        label: 'Discount Code',
        content: null,
    },
    gate_code: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Gate Code',
    },
    has_add_ons: {
        type: 'checkbox',
        rules: undefined,
        value: 0,
        errors: [],
        label: 'Would you also like to book any additional services? Tick if yes.'
    },
    key_address: {
        type: 'divider',
        label: ' ',
        content: ''
    },
    return_key_address: {
        type: 'divider',
        label: ' ',
        content: ''
    },
    key_location: {
        type: 'hidden',
        label: 'Key Safe Location',
        rules: undefined,
        value: '',
        errors: [],
    },
    key_comments: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
    },
    key_holder_info: {
        type: 'divider',
        label: 'Key-holder Info',
        content: '',
    },
    keyholder_terms: {
        type: 'checkbox',
        rules: undefined,
        value: 0,
        errors: [],
        label: (
            <>
                Please check the box to agree to our
                {' '}
                <a
                    href={`${REACT_APP_FILE_UPLOAD_BASE_URL}/userfiles/documents/Key_Holding-Terms_and_Conditions_April_2022.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`keyHolderTermsLink`}
                >
                    Key Holder Terms and Conditions
                    {' '}
                    <i key="keyHolderTermsLinkIcon" className="fa fa-fw fa-external-link"></i>
                </a>
            </>
        )
    },
    keycode: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
    },
    keynest_divider: {
        type: 'divider',
        label: 'KeyNest',
        content: 'If you have your own account with KeyNest for this property, please insert the location of the KeyNest along with the Collection Code and Drop Off Code (as required) into the fields below.',
    },
    keynest_collection_code: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'KeyNest collection code',
    },
    keynest_dropoff_code: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'KeyNest drop-off code',
    },
    keynest_store_location: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'KeyNest Store Address',
    },
    money_laundering: {
        type: 'checkbox',
        rules: undefined,
        value: 0,
        errors: [],
        label: '',
    },
    notes_divider: {
        type: 'divider',
        label: 'General Notes',
    },
    notes_viewber: {
        type: 'textarea',
        rules: undefined,
        value: '',
        errors: [],
        rows: 8,
        label: 'Notes for Viewber - This section allows you to provide specific helpful information for the Viewber relating to the appointment or property. Any additional requirements or requests added to this section, will not be completed as part of the appointment.',
        formText: '*Please be especially careful to include details of any Health and Safety Hazards at the property. Include details of pets etc that may have been left in the property. e.g. a dog in the utility room*',
    },
    out_of_hours_phone: {
        type: 'tel',
        value: '',
        rules: undefined,
        errors: [],
        label: 'Out Of Hours Contact Number',
    },
    owner_name: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
    },
    owner_phone: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
    },
    preferred_date_1: {
        type: 'react-datetime',
        value: '',
        rules: undefined,
        errors: [],
        label: 'Date/Time',
        formText: (
            <span>
                To amend the date please click the text box and select a date from the calendar.
                {' '}
                To amend the time, once the calendar is visible, click the
                {' '}
                <span key="blueBarPreferredDate1FormText" className="bg-primary">blue bar</span>
                {' '}
                at the bottom of the calendar.
            </span>
        ),
        closeOnSelect: false,
        dateFormat: momentDateFormat,
        isValidDate: (current) => {
            const yesterday = moment().subtract(1, 'day');

            return current.isAfter(yesterday);
        },
        timeFormat: momentTimeFormat,
        timeConstraints: {
            hours: {
                min: 7,
                max: 21,
            },
            minutes: {
                step: 15,
            },
        },
    },
    properties_id: {
        type: 'hidden',
        rules: undefined,
        value: '',
        errors: [],
    },
    has_recur_until: {
        type: 'checkbox',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Does this recurring request have an end date?',
    },
    property_details_divider: {
        type: 'divider',
        label: 'Additional Property Details',
        content: 'Important: Please enter a link to a brochure, this is useful for our Viewber. If you don\'t have one tick the checkbox below and you\'ll be able to upload a .pdf document on the next step.',
    },
    phone: {
        type: 'tel',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Client Contact Phone',
    },
    client_booking_reference: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Client Booking Reference',
    },
    recur_keysafe_divider: {
        type: 'divider',
        label: 'Important notes',
        content: (
            <Alert color="warning">
                Appointments containing key safe fits or removals should not be booked to recur.
            </Alert>
        ),
    },
    recur_id: {
        type: 'hidden',
        rules: undefined,
        value: '',
        errors: [],
    },
    recur_interval: {
        label: 'Interval',
        type: 'select',
        rules: undefined,
        value: '',
        errors: [],
        values: [
            {
                text: 'Weekly',
                value: 'weekly',
            },
            {
                text: 'Fortnightly',
                value: 'fortnightly',
            },
            {
                text: 'Monthly',
                value: 'monthly',
            },
            {
                text: 'Quarterly',
                value: 'quarterly',
            },
            {
                text: 'Half yearly',
                value: 'half-yearly',
            },
        ],
    },
    recur_status_divider: {
        type: 'divider',
        label: 'Recurring Status',
        content: ''
    },
    recur_original_status: {
        type: 'hidden',
        rules: undefined,
        value: '',
        errors: [],
    },
    recur_status: {
        type: 'select',
        label: 'Status',
        content: '',
        values: [
            {
                text: 'Active',
                value: 'active',
            },
            {
                text: 'Cancelled - no future appointments will be created',
                value: 'cancelled',
            },
            {
                text: 'Complete - no future appointments will be created',
                value: 'complete',
            },
        ],
    },
    recur_until: {
        label: 'End Date',
        type: 'react-datetime',
        rules: undefined,
        value: '',
        errors: [],
        closeOnSelect: false,
        dateFormat: momentDateFormat,
        isValidDate: (current) => {
            const yesterday = moment().subtract(1, 'day');

            return current.isAfter(yesterday);
        },
    },
    recurs: {
        type: 'checkbox',
        rules: undefined,
        value: 0,
        errors: [],
        label: 'Is this a recurring request?',
    },
    return_arrangement_divider: {
        type: 'divider',
        label: 'Key Return Arrangements',
        content: 'If the date of the key return is not the same as the date of the appointment, please detail in the Notes for Viewber section.',
    },
    return_arrangement_id: {
        type: 'select',
        rules: undefined,
        value: '',
        errors: [],
        values: [],
        label: 'Please select - How should the property keys be returned?',
        emptyOption: 'Please select - How should the property keys be returned?',
        selectOptionValue: returnArrangementSelectOptionValue,
        valuesFetcher: returnArrangementValuesFetcher,
        selectOptionText: returnArrangementSelectOptionText
    },
    return_city: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
    },
    return_line_1: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Address Line 1',
    },
    return_line_2: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Address Line 2',
    },
    return_name: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Contact Name',
    },
    return_phone: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        label: 'Phone',
    },
    return_postcode: {
        type: 'text',
        rules: undefined,
        value: '',
        errors: [],
        lable: 'Postcode',
    },
    return_travel_time: {
        type: 'hidden',
        rules: undefined,
        formText: 'Please calculate the driving travel time, in minutes, between the property and the return address using Google Maps.',
        value: '',
        errors: [],
        label: 'Travel Time (mins)',
    },
    submit_booking_divider: {
        type: 'divider',
        label: 'Submit This Booking',
    },
    terms: {
        type: 'checkbox',
        rules: undefined,
        value: 0,
        errors: [],
        label: (
            <>
                {`Please check the box to agree to our `}
                <a
                    key={`label_terms_link`}
                    href={`${REACT_APP_FILE_UPLOAD_BASE_URL}/userfiles/documents/Terms_and_Conditions_-_Viewber_202302.pdf`}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    terms and conditions 
                </a>
            </>
        ),
    },
    viewing_length_id: {
        type: 'select',
        rules: undefined,
        value: '',
        errors: [],
        emptyOption: 'Select appointment duration or type...',
        values: [],
        dontGetResourceValue: true,
        label: 'Please select an appointment duration or type',
        selectOptionValue: viewingLengthSelectOptionValue,
        selectOptionText: viewingLengthSelectOptionText,
        valuesFetcher: viewingLengthValuesFetcher,
    },
    viewing_length_see_more: {
        type: 'see_more_link',
        value: '',
        style: {
            display: 'none',
        },
    },
    viewing_type_divider: {
        type: 'divider',
        label: 'Type of Appointment',
    },
    viewing_type_id: {
        type: 'select',
        rules: undefined,
        value: '',
        errors: [],
        values: [],
        dontGetResourceValue: true,
        label: 'Please select appointment category',
        emptyOption: 'Select appointment category...',
        selectOptionValue: viewingTypeSelectOptionValue,
        selectOptionText: viewingTypeSelectOptionText,
    },
};

/**
 * The default schema is already defined above as const schema.
 * Here we have the attributes we need to change for a field
 * to change it to a particular variant.
 */
export const schemaVariants = {
    preferred_date_1: {
        date_and_time: [
            { attribute: 'label', value: 'Date/Time' },
            {
                attribute: 'formText', value: (
                    <span>
                        To amend the date please click the text box and select a date from the calendar.
                        {' '}
                    To amend the time, once the calendar is visible, click the
                        {' '}
                        <span key="blueBarPreferredDate1FormText" className="bg-primary">blue bar</span>
                        {' '}
                    at the bottom of the calendar.
                    </span>
                )
            },
            { attribute: 'timeFormat', value: momentTimeFormat },
        ],
        date_only: [
            { attribute: 'label', value: 'Date' },
            { attribute: 'formText', value: 'To amend the date please click the text box and select a date from the calendar.' },
            { attribute: 'timeFormat', value: false },
        ]
    }
};

export const attributesSequenceToShow = [
    'viewing_type_divider',
    'id',
    'viewing_type_id',
    'viewing_length_id',
    'viewing_length_see_more',
    'category',
    'has_add_ons',
    'add_ons_divider',
    'properties_id',
    'date_divider',
    'date_type',
    'date_type_deadline_alert',
    'preferred_date_1',
    'date_time_notice_alert',
    'recur_keysafe_divider',
    'recur_status_divider',
    'recur_status',
    'recur_original_status',
    'recurs',
    'recur_id',
    'recur_interval',
    'has_recur_until',
    'recur_until',
    'access_arrangement_divider',
    'access_arrangement_id',
    'gate_code',
    'collection_name',
    'collection_phone',
    'collection_line_1',
    'collection_line_2',
    'collection_city',
    'collection_postcode',
    'collection_travel_time',
    'keynest_divider',
    'keynest_store_location',
    'keynest_dropoff_code',
    'keynest_collection_code',
    'keycode',
    'key_address',
    'key_location',
    'key_comments',
    'key_holder_info',
    'owner_name',
    'owner_phone',
    'concierge_location',
    'return_arrangement_divider',
    'return_arrangement_id',
    'return_city',
    'return_line_1',
    'return_line_2',
    'return_name',
    'return_phone',
    'return_postcode',
    'return_travel_time',
    'property_details_divider',
    'brochure',
    'brochure_not_applicable',
    'client_notes',
    'notes_divider',
    'notes_viewber',
    'client_details_divider',
    'phone',
    'client_booking_reference',
    'out_of_hours_phone',
    'discount_divider',
    'discount_code',
    'submit_booking_divider',
    'keyholder_terms',
    'money_laundering',
    'terms',
];

// Use exclusively in booking form,
// do not copy in other schemas as not relevant please
export const steps = [
    {
        attributesSequenceToShow: [
            'viewing_type_divider',
            'viewing_type_id',
            'viewing_length_id',
            'viewing_length_see_more',
            'category',
            'has_add_ons',
            'add_ons_divider',
            'properties_id',
        ],
    },
    {
        attributesSequenceToShow: [
            'date_divider',
            'date_type',
            'date_type_deadline_alert',
            'preferred_date_1',
            'date_time_notice_alert',
            'key_holder_info',
            'recur_keysafe_divider',
            'recurs',
            'recur_interval',
            'has_recur_until',
            'recur_until',
        ],
    },
    {
        attributesSequenceToShow: [
            'access_arrangement_divider',
            'access_arrangement_id',
            'collection_name',
            'collection_phone',
            'key_address',
            'collection_line_1',
            'collection_line_2',
            'collection_city',
            'collection_postcode',
            'collection_travel_time',
            'keycode',
            'key_location',
            'key_comments',
            'keynest_divider',
            'keynest_store_location',
            'keynest_dropoff_code',
            'keynest_collection_code',
            'owner_name',
            'owner_phone',
            'concierge_location',
            'key_holder_info',
            'gate_code',
        ],
    },
    {
        attributesSequenceToShow: [
            'return_arrangement_divider',
            'return_arrangement_id',
            'return_key_address',
            'return_city',
            'return_line_1',
            'return_line_2',
            'return_name',
            'return_phone',
            'return_postcode',
            'return_travel_time',
            'collection_name',
            'collection_phone',
            'collection_line_1',
            'collection_line_2',
            'collection_city',
            'collection_postcode',
            'collection_travel_time',
        ],
    },
    {
        name: 'viewer-details',
    },
    {
        attributesSequenceToShow: [
            'property_details_divider',
            'brochure',
            'brochure_not_applicable',
            'client_notes',
        ],
    },
    {
        name: 'document-upload',
    },
    {
        attributesSequenceToShow: [
            'notes_divider',
            'notes_viewber',
        ],
    },
    {
        attributesSequenceToShow: [
            'client_details_divider',
            'phone',
            'client_booking_reference',
            'out_of_hours_phone',
        ],
    },
    {
        attributesSequenceToShow: [
            'discount_divider',
            'discount_code',
            'submit_booking_divider',
            'keyholder_terms',
            'money_laundering',
            'terms',
        ],
    },
];

export const defaultUserValuesToSet = [
    { origin: 'out_of_hours_phone', destination: 'out_of_hours_phone' },
    { origin: 'client_booking_reference', destination: 'client_booking_reference' },
    { origin: 'phone', destination: 'phone' },
];

export const defaultPropertyValuesToSet = [
    { origin: 'access_arrangement_id', destination: 'access_arrangement_id' },
    { origin: 'id', destination: 'properties_id' },
    { origin: 'brochure', destination: 'brochure' },
    { origin: 'category', destination: 'category' },
    { origin: 'client_notes', destination: 'client_notes' },
    { origin: 'collection_name', destination: 'collection_name' },
    { origin: 'collection_phone', destination: 'collection_phone' },
    { origin: 'collection_line_1', destination: 'collection_line_1' },
    { origin: 'collection_line_2', destination: 'collection_line_2' },
    { origin: 'collection_city', destination: 'collection_city' },
    { origin: 'collection_postcode', destination: 'collection_postcode' },
    { origin: 'collection_travel_time', destination: 'collection_travel_time' },
    { origin: 'concierge_location', destination: 'concierge_location' },
    { origin: 'gate_code', destination: 'gate_code' },
    { origin: 'key_comments', destination: 'key_comments' },
    { origin: 'key_location', destination: 'key_location' },
    { origin: 'keycode', destination: 'keycode' },
    { origin: 'owner_name', destination: 'owner_name' },
    { origin: 'owner_phone', destination: 'owner_phone' },
    { origin: 'return_arrangement_id', destination: 'return_arrangement_id' },
    { origin: 'return_city', destination: 'return_city' },
    { origin: 'return_line_1', destination: 'return_line_1' },
    { origin: 'return_line_2', destination: 'return_line_2' },
    { origin: 'return_name', destination: 'return_name' },
    { origin: 'return_phone', destination: 'return_phone' },
    { origin: 'return_postcode', destination: 'return_postcode' },
    { origin: 'return_travel_time', destination: 'return_travel_time' },
    { origin: 'keynest_collection_code', destination: 'keynest_collection_code' },
    { origin: 'keynest_dropoff_code', destination: 'keynest_dropoff_code' },
    { origin: 'keynest_store_location', destination: 'keynest_store_location' },
];

export const attributesToCopyMap = {
    collection_city: 'return_city',
    collection_line_1: 'return_line_1',
    collection_line_2: 'return_line_2',
    collection_name: 'return_name',
    collection_phone: 'return_phone',
    collection_postcode: 'return_postcode',
    collection_travel_time: 'return_travel_time',
};

export default schema;
