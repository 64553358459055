const setResourceFieldAttributeValue = (schema, field, attribute, value) => {
    return {
        ...schema,
        [field]: {
            ...schema[field],
            [attribute]: value
        }
    };
};

export default setResourceFieldAttributeValue;
