import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '../Form';
import FormikTextInput from './FormikTextInput';
import FormikNumberInput from './FormikNumberInput';

const FormikViewingReturnInputs = ({
    inputIdPrefix,
    inputNamesMap
}) => {
    const makeInputId = inputId => {
        if (inputIdPrefix) {
            return `${inputIdPrefix}-${inputId}`;
        }
        return inputId;
    }

    const makeInputName = inputName => {
        if (inputNamesMap && inputNamesMap[inputName]) {
            return inputNamesMap[inputName];
        }
        return inputName;
    }

    return (
        <React.Fragment>
            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-return-name-input')}
                    name={makeInputName('return_name')}
                    label='Contact Name'
                    placeholder='Enter the Contact Name'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-return-phone-input')}
                    name={makeInputName('return_phone')}
                    label='Contact Phone'
                    placeholder='Enter the Contact Phone'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-return-line-1-input')}
                    name={makeInputName('return_line_1')}
                    label='Address Line 1'
                    placeholder='Enter the Address Line 1'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-return-line-2-input')}
                    name={makeInputName('return_line_2')}
                    label='Address Line 2'
                    placeholder='Enter the Address Line 2'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-return-city-input')}
                    name={makeInputName('return_city')}
                    label='City'
                    placeholder='Enter the City'
                />
            </FormGroup>

            <FormGroup>
                <FormikTextInput
                    id={makeInputId('viewing-return-postcode-input')}
                    name={makeInputName('return_postcode')}
                    label='Postcode'
                    placeholder='Enter the Postcode'
                />
            </FormGroup>

            <FormGroup>
                <FormikNumberInput
                    id={makeInputId('viewing-return-travel-time-input')}
                    name={makeInputName('return_travel_time')}
                    label='Travel Time (mins)'
                    placeholder='Enter the Travel Time (mins)'
                    helpText='Please calculate the driving travel time, in minutes, between the property and the return address using Google Maps.'
                />
            </FormGroup>
        </React.Fragment>
    )
}

FormikViewingReturnInputs.propTypes = {
    /**
     * A prefix for the input ids.
     */
    inputIdPrefix: PropTypes.string,

    /**
     * A map of names for the input to use
     * instead of the default name.
     * E.g.
     * {
     *    'return_name': 'new_return_name,
     *    'return_phone': 'new_return_phone,
     * }
     */
    inputNamesMap: PropTypes.object
}

export default FormikViewingReturnInputs;