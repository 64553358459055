// Having reducerName in a different files allow the whole schema not to be compiled,
// before redux initializes, causing valuesFetcher to have an undefined fetcher callback.
// Please do not move
import { reducerName } from './variables';
export { reducerName };

export const selectOptionValue = 'id';

export const selectOptionText = 'name';

const schema = {
    // name: {
    //     type: 'text',
    //     value: '',
    //     rules: yup.string()
    //         .required()
    //         .max(255),
    //     errors: [],
    // },
};

export default schema;
