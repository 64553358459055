import apiRequest from '../apiRequest';

export const get = async ({ token, params, data }) =>
    apiRequest('documents', {
        token,
        params,
        data
    });

export const toRead = ({ token }) =>
    apiRequest('documents/to-read', {
        method: 'GET',
        token
    });