import createActionName from '../createActionName';
import { reducerName } from './variables';

export const CHANGE_PAGE_RESOURCES = createActionName(reducerName, 'CHANGE_PAGE_RESOURCES');
export const CLEAR_METADATA_RESOURCES = createActionName(reducerName, 'CLEAR_METADATA_RESOURCES');
export const GET_TO_READ_RESOURCES_REQUEST = createActionName(reducerName, 'GET_TO_READ_RESOURCES_REQUEST');
export const GET_TO_READ_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_TO_READ_RESOURCES_SUCCESS');
export const GET_TO_READ_RESOURCES_ERROR = createActionName(reducerName, 'GET_TO_READ_RESOURCES_ERROR');
export const GET_ALL_RESOURCES_REQUEST = createActionName(reducerName, 'GET_ALL_RESOURCES_REQUEST');
export const GET_ALL_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_ALL_RESOURCES_SUCCESS');
export const GET_ALL_RESOURCES_ERROR = createActionName(reducerName, 'GET_ALL_RESOURCES_ERROR');

// actions
const actions = {
  	CHANGE_PAGE_RESOURCES,
    CLEAR_METADATA_RESOURCES,
    GET_TO_READ_RESOURCES_REQUEST,
    GET_TO_READ_RESOURCES_SUCCESS,
    GET_TO_READ_RESOURCES_ERROR,
    GET_ALL_RESOURCES_REQUEST,
    GET_ALL_RESOURCES_SUCCESS,
    GET_ALL_RESOURCES_ERROR,
};

// action creators
export const changePageResources = payload => ({
    type: actions.CHANGE_PAGE_RESOURCES,
    payload,
});

export const clearMetadataResources = payload => ({
    type: actions.CLEAR_METADATA_RESOURCES,
    payload,
});

export const getToReadResources = payload => ({
    type: actions.GET_TO_READ_RESOURCES_REQUEST,
    payload,
});

export const getAllResources = payload => ({
    type: actions.GET_ALL_RESOURCES_REQUEST,
    payload,
});

export default actions;
