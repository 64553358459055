const Rollbar = require("rollbar");
const RollbarFake = require('./rollbar-fake');

var rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    // captureUncaught: true,
    // captureUnhandledRejections: true,
    payload: {
        environment: process.env.NODE_ENV
    }
};

const rollbar = process.env.REACT_APP_ENABLE_ROLLBAR === 'true'
    ? new Rollbar(rollbarConfig)
    : new RollbarFake();

export default rollbar;