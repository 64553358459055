import createActionName from '../createActionName';
import { reducerName } from './variables';

export const CANCEL_RESOURCE_REQUEST = createActionName(reducerName, 'CANCEL_RESOURCE_REQUEST');
export const CANCEL_RESOURCE_SUCCESS = createActionName(reducerName, 'CANCEL_RESOURCE_SUCCESS');
export const CANCEL_RESOURCE_ERROR = createActionName(reducerName, 'CANCEL_RESOURCE_ERROR');
export const CHANGE_PAGE_RESOURCES = createActionName(reducerName, 'CHANGE_PAGE_RESOURCES');
export const CLEAR_METADATA_RESOURCES = createActionName(reducerName, 'CLEAR_METADATA_RESOURCES');
export const CLEAR_METADATA_RESOURCE_EDIT = createActionName(reducerName, 'CLEAR_METADATA_RESOURCE_EDIT');
export const CLEAR_METADATA_RESOURCE_CREATE = createActionName(reducerName, 'CLEAR_METADATA_RESOURCE_CREATE');
export const CREATE_RESOURCE_REQUEST = createActionName(reducerName, 'CREATE_RESOURCE_REQUEST');
export const CREATE_RESOURCE_SUCCESS = createActionName(reducerName, 'CREATE_RESOURCE_SUCCESS');
export const CREATE_RESOURCE_ERROR = createActionName(reducerName, 'CREATE_RESOURCE_ERROR');
export const DESTROY_RESOURCE_REQUEST = createActionName(reducerName, 'DESTROY_RESOURCE_REQUEST');
export const DESTROY_RESOURCE_SUCCESS = createActionName(reducerName, 'DESTROY_RESOURCE_SUCCESS');
export const DESTROY_RESOURCE_ERROR = createActionName(reducerName, 'DESTROY_RESOURCE_ERROR');
export const FIND_RESOURCE_REQUEST = createActionName(reducerName, 'FIND_RESOURCE_REQUEST');
export const FIND_RESOURCE_SUCCESS = createActionName(reducerName, 'FIND_RESOURCE_SUCCESS');
export const FIND_RESOURCE_ERROR = createActionName(reducerName, 'FIND_RESOURCE_ERROR');
export const GET_DATE_FILTERED_RESOURCES_REQUEST = createActionName(reducerName, 'GET_DATE_FILTERED_RESOURCES_REQUEST');
export const GET_DATE_FILTERED_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_DATE_FILTERED_RESOURCES_SUCCESS');
export const GET_DATE_FILTERED_RESOURCES_ERROR = createActionName(reducerName, 'GET_DATE_FILTERED_RESOURCES_ERROR');
export const GET_PAGINATED_RESOURCES_REQUEST = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_REQUEST');
export const GET_PAGINATED_RESOURCES_SUCCESS = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_SUCCESS');
export const GET_PAGINATED_RESOURCES_ERROR = createActionName(reducerName, 'GET_PAGINATED_RESOURCES_ERROR');
export const SET_ERROR = createActionName(reducerName, 'SET_ERROR');
export const SET_RELATIONSHIP_ITEM_RESOURCE = createActionName(reducerName, 'SET_RELATIONSHIP_ITEM_RESOURCE');
export const UNSET_RELATIONSHIP_ITEM_RESOURCE = createActionName(reducerName, 'UNSET_RELATIONSHIP_ITEM_RESOURCE');
export const UPDATE_RESOURCE_REQUEST = createActionName(reducerName, 'UPDATE_RESOURCE_REQUEST');
export const UPDATE_RESOURCE_SUCCESS = createActionName(reducerName, 'UPDATE_RESOURCE_SUCCESS');
export const UPDATE_RESOURCE_ERROR = createActionName(reducerName, 'UPDATE_RESOURCE_ERROR');
export const FIND_CONFIRMED_APPOINTMENT_RESOURCE_REQUEST = createActionName(reducerName, 'FIND_CONFIRMED_APPOINTMENT_RESOURCE_REQUEST');
export const FIND_CONFIRMED_APPOINTMENT_RESOURCE_SUCCESS = createActionName(reducerName, 'FIND_CONFIRMED_APPOINTMENT_RESOURCE_SUCCESS');
export const FIND_CONFIRMED_APPOINTMENT_RESOURCE_ERROR = createActionName(reducerName, 'FIND_CONFIRMED_APPOINTMENT_RESOURCE_ERROR');
export const VALIDATE_RESOURCE_REQUEST = createActionName(reducerName, 'VALIDATE_RESOURCE_REQUEST');
export const VALIDATE_RESOURCE_SUCCESS = createActionName(reducerName, 'VALIDATE_RESOURCE_SUCCESS');
export const VALIDATE_RESOURCE_ERROR = createActionName(reducerName, 'VALIDATE_RESOURCE_ERROR');

// actions
const actions = {
	CANCEL_RESOURCE_REQUEST,
	CANCEL_RESOURCE_SUCCESS,
	CANCEL_RESOURCE_ERROR,
	CHANGE_PAGE_RESOURCES,
	CLEAR_METADATA_RESOURCES,
	CLEAR_METADATA_RESOURCE_EDIT,
	CLEAR_METADATA_RESOURCE_CREATE,
	CREATE_RESOURCE_REQUEST,
	CREATE_RESOURCE_SUCCESS,
	CREATE_RESOURCE_ERROR,
	DESTROY_RESOURCE_REQUEST,
	DESTROY_RESOURCE_SUCCESS,
	DESTROY_RESOURCE_ERROR,
    FIND_CONFIRMED_APPOINTMENT_RESOURCE_REQUEST,
    FIND_CONFIRMED_APPOINTMENT_RESOURCE_SUCCESS,
    FIND_CONFIRMED_APPOINTMENT_RESOURCE_ERROR,
	FIND_RESOURCE_REQUEST,
	FIND_RESOURCE_SUCCESS,
	FIND_RESOURCE_ERROR,
    GET_DATE_FILTERED_RESOURCES_REQUEST,
    GET_DATE_FILTERED_RESOURCES_SUCCESS,
    GET_DATE_FILTERED_RESOURCES_ERROR,
	GET_PAGINATED_RESOURCES_REQUEST,
	GET_PAGINATED_RESOURCES_SUCCESS,
	GET_PAGINATED_RESOURCES_ERROR,
    SET_ERROR,
	SET_RELATIONSHIP_ITEM_RESOURCE,
	UNSET_RELATIONSHIP_ITEM_RESOURCE,
	UPDATE_RESOURCE_REQUEST,
	UPDATE_RESOURCE_SUCCESS,
	UPDATE_RESOURCE_ERROR,
    VALIDATE_RESOURCE_REQUEST,
    VALIDATE_RESOURCE_SUCCESS,
    VALIDATE_RESOURCE_ERROR,
};

// action creators
export const changePageResources = payload => ({
    type: actions.CHANGE_PAGE_RESOURCES,
	payload,
});

export const clearMetadataResourceCreate = payload => ({
    type: actions.CLEAR_METADATA_RESOURCE_CREATE,
});

export const clearMetadataResourceEdit = payload => ({
    type: actions.CLEAR_METADATA_RESOURCE_EDIT,
});

export const clearMetadataResources = payload => ({
    type: actions.CLEAR_METADATA_RESOURCES,
    payload,
});

export const cancelResource = payload => ({
    type: actions.CANCEL_RESOURCE_REQUEST,
    payload,
});

export const createResource = payload => ({
    type: actions.CREATE_RESOURCE_REQUEST,
    payload,
});

export const destroyResource = payload => ({
    type: actions.DESTROY_RESOURCE_REQUEST,
    payload,
});

export const findConfirmedAppointmentResource = payload => ({
    type: actions.FIND_CONFIRMED_APPOINTMENT_RESOURCE_REQUEST,
    payload,
});

export const findResource = payload => ({
    type: actions.FIND_RESOURCE_REQUEST,
    payload,
});

export const getDateFilteredResources = payload => ({
    type: actions.GET_DATE_FILTERED_RESOURCES_REQUEST,
    payload,
});

export const getPaginatedResources = payload => ({
    type: actions.GET_PAGINATED_RESOURCES_REQUEST,
    payload,
});

export const setError = payload => ({
    type: actions.SET_ERROR,
    payload,
});

export const setRelationshipItem = payload => ({
    type: actions.SET_RELATIONSHIP_ITEM_RESOURCE,
    payload,
});

export const unsetRelationshipItem = payload => ({
    type: actions.UNSET_RELATIONSHIP_ITEM_RESOURCE,
    payload,
});

export const updateResource = payload => ({
    type: actions.UPDATE_RESOURCE_REQUEST,
    payload,
});

export const validateResource = payload => ({
    type: actions.VALIDATE_RESOURCE_REQUEST,
    payload
});

export default actions;
