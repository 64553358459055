const filterSchemaFromAttributes = (schema, attributesSequenceToShow = []) => {
    if(!schema || !attributesSequenceToShow || !attributesSequenceToShow.length) {
        return {};
    }

    const newSchema = attributesSequenceToShow.reduce(
        (result, attributeName) => {
            return {
                ...result,
                [attributeName]: {...schema[attributeName]},
            }
        },
        {}
    );

    return newSchema;
};

export default filterSchemaFromAttributes;
