import { all, takeEvery, fork } from 'redux-saga/effects';
import actions from './actions';
import { sagaApiRequest } from '../helpers';
import * as propertiesAPI from '../../api/properties';

export function* createResourceRequest() {
    yield takeEvery(actions.CREATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: propertiesAPI.create,
            args: payload.data,
            onSuccess: actions.CREATE_RESOURCE_SUCCESS,
            onError: actions.CREATE_RESOURCE_ERROR
        });
    });
}

export function* findResourceRequest() {
    yield takeEvery(actions.FIND_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: propertiesAPI.find,
            args: [payload.data.id, payload.data.token],
            onSuccess: actions.FIND_RESOURCE_SUCCESS,
            onError: actions.FIND_RESOURCE_ERROR
        });
    });
}

export function* getAllResourcesRequest() {
    yield takeEvery(actions.GET_ALL_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: propertiesAPI.get,
            args: payload.data,
            onSuccess: actions.GET_ALL_RESOURCES_SUCCESS,
            onError: actions.GET_ALL_RESOURCES_ERROR
        });
    });
}

export function* destroyResourceRequest() {
    yield takeEvery(actions.DESTROY_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: propertiesAPI.destroy,
            args: [payload.data.id, payload.data.token],
            onSuccess: actions.DESTROY_RESOURCE_SUCCESS,
            onError: actions.DESTROY_RESOURCE_ERROR
        });
    });
}

export function* getPackRequest() {
    yield takeEvery(actions.GET_PACK_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: propertiesAPI.propertyViewingPack,
            args: [payload.data.id, payload.data.token],
            onSuccess: actions.GET_PACK_SUCCESS,
            onError: actions.GET_PACK_ERROR
        });
    });
}

export function* getPaginatedResourcesRequest() {
    yield takeEvery(actions.GET_PAGINATED_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: propertiesAPI.paginate,
            args: payload.data,
            onSuccess: actions.GET_PAGINATED_RESOURCES_SUCCESS,
            onError: actions.GET_PAGINATED_RESOURCES_ERROR
        });
    });
}

export function* getPropertyKeyHolderUnavailabilityResourcesRequest() {
    yield takeEvery(actions.GET_PROPERTY_KEY_HOLDER_UNAVAILABILITY_RESOURCES_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: propertiesAPI.keyHolderUnavailability,
            args: payload.data,
            onSuccess: actions.GET_PROPERTY_KEY_HOLDER_UNAVAILABILITY_RESOURCES_SUCCESS,
            onError: actions.GET_PROPERTY_KEY_HOLDER_UNAVAILABILITY_RESOURCES_ERROR
        });
    });
}

export function* updateResourceRequest() {
    yield takeEvery(actions.UPDATE_RESOURCE_REQUEST, function*({ payload }) {
        yield* sagaApiRequest({
            request: propertiesAPI.update,
            args: payload.data,
            onSuccess: actions.UPDATE_RESOURCE_SUCCESS,
            onError: actions.UPDATE_RESOURCE_ERROR
        });
    });
}

export default function* rootSaga() {
    yield all([
        fork(createResourceRequest),
        fork(destroyResourceRequest),
        fork(findResourceRequest),
        fork(getAllResourcesRequest),
        fork(getPackRequest),
        fork(getPaginatedResourcesRequest),
        fork(getPropertyKeyHolderUnavailabilityResourcesRequest),
        fork(updateResourceRequest),
    ]);
}
