import getSchemaValueFromKeyAndParameter from './getSchemaValueFromKeyAndParameter';
import getSelectOptionValues from './getSelectOptionValues';
import getTypeFromKey from './getTypeFromKey';

const getFormResourceFromValues = (values, schema, attributesSequenceToShow = []) => {
    if(
        typeof values === 'undefined'
        || values === null
    ) {
        return schema;
    }

    const resource = attributesSequenceToShow.reduce(
        (result, key) => {
            // dontGetResourceValue avoids copying the field from the resource,
            // which for example in case of passwords,
            // is not transmitted by the API for security reasons.
            // This prevents the "changing component from controlled to uncontrolled" React error
            const value = schema[key].dontGetResourceValue
                ? schema[key].value
                : values[key];

            // Disabled attribute
            let disabled = false;
            if(schema[key] && typeof schema[key].disabled !== 'undefined') {
                disabled = schema[key].disabled;
            }
            // else if(key.length > 3 && key.indexOf('_at') === key.length - 3) {
            //     // We disable everything ending in "_at" to avoid people to fiddle around with timestamps
            //     disabled = true;
            // }

            // Type
            const type = getTypeFromKey(schema, key);

            // multiple prop for selects
            const multiple = getSchemaValueFromKeyAndParameter(schema, key, 'multiple');

            // selectOptionText prop to decide how the text of a select/react-select option is going to look like
            const selectOptionText = getSchemaValueFromKeyAndParameter(schema, key, 'selectOptionText');

            // selectOptionValue prop to decide how the value of a select/react-select option is going to look like
            const selectOptionValue = getSchemaValueFromKeyAndParameter(schema, key, 'selectOptionValue');

            let newValue;
            if(
                (
                    type === 'select'
                    || type === 'react-select'
                )
                && multiple === true
                && value
                && value.length
                && typeof selectOptionText !== 'undefined'
                && typeof selectOptionValue !== 'undefined'
                && schema[key]
                && schema[key].values
            ) {
                // If it's a multiple select (or react-select)
                // And the value is an array,
                // And there are a selectOptionText and selectOptionValue
                // We transform the value array in an array of object containing
                // the values in the format needed by the select
                newValue = getSelectOptionValues(
                    schema[key].values,
                    value,
                    selectOptionText,
                    selectOptionValue
                );
            }

            return {
                ...result,
                [key]: {
                    ...schema[key],
                    disabled,
                    type,
                    errors: [],
                    value: newValue ? newValue : value,
                }
            };
        },
        {}
    );

    return resource;
};

export default getFormResourceFromValues;
